<template>
  <div id="popup-question" class="popup-question" style="display: none">
    <div
      class="popup-question__title"
      v-if="popup.form_popup_title"
      v-html="popup.form_popup_title"
    ></div>
    <Subtitle
      parent-class="popup-question__desc"
      v-if="popup.form_popup_desc"
      v-html="popup.form_popup_desc"
    ></Subtitle>
    <form class="popup-question__form" action="#">
      <p class="popup-question__field">
        <Label parent-class="popup-question__label">Имя</Label>
        <span class="input_wrapper">
          <InputText
            parent-class="popup-question__input"
            type="text"
            name="popup-question-name"
            v-model="dataForm.your_name"
          />
        </span>
      </p>
      <p
        :class="
          errors ? 'popup-question__field error' : 'popup-question__field'
        "
      >
        <Label parent-class="popup-question__label">{{
          errors ? "* Поле “Телефон” не указано" : "* Телефон"
        }}</Label>
        <span class="input_wrapper">
          <InputText
            parent-class="popup-question__input"
            name="popup-question-phone"
            v-input-mask
            v-model="dataForm.your_phone"
            type="tel"
            placeholder="+ 7 ("
          />
        </span>
      </p>
      <Button
        parent-class="popup-question__button"
        v-on:click.prevent="submitForm"
        v-if="popup.form_popup_button"
        >{{ popup.form_popup_button }}</Button
      >
      <p class="popup-question__politics">{{ politics }}</p>
    </form>
  </div>
</template>

<script>
import Subtitle from "@/components/element/SubtitleApp.vue";
import InputText from "@/components/element/InputTextApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { validation } from "@/helpers/index.js";

export default {
  components: {
    Subtitle,
    Button,
    InputText,
    Label,
  },
  data() {
    return {
      popup: this.$store.state.questionPopup.data,
      politics:
        this.$store.state.data.contentResponse.contacts.contacts_politics,
      dataForm: {
        id: 1034,
        your_name: "",
        your_phone: "",
      },
      errors: false,
    };
  },
  methods: {
    submitForm() {
      this.errors = false;

      if (validation(this.dataForm.your_phone.length, 18)) {
        if (this.popup.form_popup_button_metka) {
          this.$metrika.reachGoal(this.popup.form_popup_button_metka);
        }

        this.$store.dispatch("submitForm", this.dataForm);

        this.dataForm = {
          id: 1034,
          your_name: "",
          your_phone: "",
        };

        setTimeout(() => {
          Fancybox.close();
        }, 300);
      } else {
        this.errors = true;
      }
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-question",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateQuestionPopup", {
              status: false,
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
        template: {
          closeButton:
            '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 0.700394 -0.713757 0.700394 20 -0.000244141)" fill="#929292"/><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 -0.700394 0.713757 0.700394 1.00003 0.999756)" fill="#929292"/></svg>',
        },
      }
    );
  },
};
</script>

<style>
.popup-question {
  width: 450px;
  padding: 80px 54px;

  background-color: #ffffff;
}

.popup-question__title {
  margin-bottom: 8px;

  font-family: var(--title3-fonts-family);
  font-weight: 500;
  font-size: var(--title3-fonts-size);
  line-height: var(--title3-line-height);
  color: var(--title3-color-white);
  text-align: center;
}

.popup-question__desc {
  margin-bottom: 40px;

  text-align: center;
}

.popup-question__label {
  margin-left: 15px;
  margin-bottom: 10px;
}

.popup-question__input {
  width: 100%;
  margin-bottom: 10px;
}

.popup-question__button {
  width: 100%;
  margin-bottom: 25px;
}

#popup-question .carousel__button.is-close {
  top: 5px;
  right: 5px;
}

#popup-question .carousel__button.is-close {
  top: 8px;
  right: 8px;
}

#popup-question .carousel__button svg {
  width: auto;
  height: auto;
  stroke: none;
  filter: none;
  stroke-width: 1px;
  stroke-linejoin: bevel;
  stroke-linecap: round;
}

#popup-question .carousel__button svg rect {
  will-change: transform;
}

@media (max-width: 1599.98px) {
  .popup-question__title {
    font-size: 30px;
  }

  .popup-question__desc {
    margin-bottom: 35px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-order__politics {
    font-size: 10px;
  }
}

@media screen and (max-width: 1199px) {
  .popup-question {
    width: 400px;
    padding: 50px 40px;
  }

  .popup-question__title {
    font-size: 32px;
  }

  .popup-question__desc {
    margin-bottom: 30px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-question__politics {
    font-size: 10px;
  }
}

@media screen and (max-width: 991px) {
  .popup-question {
    width: 340px;
    padding: 40px 30px;
  }

  .popup-question__title {
    font-size: 26px;
  }

  .popup-question__desc {
    margin-bottom: 30px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .popup-question {
    width: 290px;
    padding: 40px 20px;
  }

  .popup-question__title {
    font-size: 24px;
  }

  .popup-question__desc {
    margin-bottom: 20px;
  }

  .popup-question__button {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
