<template>
  <section id="progress-work" class="progress-work">
    <div class="container">
      <div
        class="progress-work__title"
        v-if="content.progress_work_title"
        v-html="content.progress_work_title"
      ></div>
      <div
        class="progress-work__subtitle"
        v-if="content.progress_work_desc"
        v-html="content.progress_work_desc"
      ></div>
      <div class="progress-work__wrapper">
        <ul class="progress-work__list" v-if="content.progress_work_list">
          <ItemProgressWork
            v-for="(item, index) in content.progress_work_list"
            :key="item"
            :content="item"
            :number="index"
          />
        </ul>
        <div
          class="progress-work__line"
          data-aos="custom-work"
          data-aos-duration="1500"
          data-aos-easing="lineage"
          :data-aos-delay="650"
        >
          <svg
            class="progress-work__line-desktop"
            width="1076"
            height="60"
            viewBox="0 0 1076 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 18.4615L143.13 49.6027C192.438 60.4063 243.5 60.4165 292.812 49.6325L472.433 10.3519C521.7 -0.422079 572.713 -0.422054 621.979 10.3519L801.697 49.6539C850.947 60.4241 901.942 60.4279 951.193 49.6649L1075.5 22.5"
              stroke="#C4C4C4"
              stroke-width="3"
            />
          </svg>
          <svg
            class="progress-work__line-mobile"
            width="32"
            height="464"
            viewBox="0 0 32 464"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.91026 463L24.5325 408.631C31.3892 383.137 31.3956 356.284 24.5511 330.785L7.43898 267.038C0.600703 241.563 0.600703 214.735 7.43898 189.261L24.5647 125.462C31.4007 99.9962 31.403 73.1782 24.5716 47.711L12.0417 0.999993"
              stroke="#C4C4C4"
              stroke-width="3"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ItemProgressWork from "@/components/block/ItemProgressWorkApp";

export default {
  components: {
    ItemProgressWork,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.progress_work,
    };
  },
};
</script>

<style>
.progress-work {
  margin-bottom: 170px;
}

.progress-work__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.progress-work__subtitle {
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.progress-work__wrapper {
  position: relative;
  z-index: 0;
}

.progress-work__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
}

.progress-work__line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -2;

  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.progress-work__line path {
  stroke: var(--backgraund-site-dark);
  stroke-opacity: 0.4;
}

.progress-work__line-desktop {
  width: 1076px;
  height: 72px;
}

.progress-work__line-mobile {
  display: none;
}

[data-aos="custom-work"] {
  overflow: hidden;
  clip-path: inset(0 100% 0 0);
  transition: 1.2s cubic-bezier(0.4, 0.08, 0, 1.03);
  transition-property: clip-path;
}

[data-aos="custom-work"].aos-animate {
  clip-path: inset(0 0 0 0);
}

@media (max-width: 1599.98px) {
  .progress-work {
    margin-bottom: 150px;
  }

  .progress-work__title {
    font-size: 40px;
    line-height: 120%;
  }

  .progress-work__subtitle {
    max-width: 450px;
    margin-bottom: 60px;

    font-size: 12px;
  }

  .progress-work__line {
    height: 60px;
  }

  .progress-work__line-desktop {
    width: 875px;
    height: 60px;
  }
}

@media (max-width: 1199.98px) {
  .progress-work {
    margin-bottom: 130px;
  }

  .progress-work__title {
    font-size: 32px;
  }

  .progress-work__subtitle {
    max-width: 364px;
    margin-bottom: 55px;
  }

  .progress-work__line {
    height: 54px;
  }

  .progress-work__line-desktop {
    width: 795px;
    height: 54px;
  }
}

@media (max-width: 991.98px) {
  .progress-work {
    margin-bottom: 110px;
  }

  .progress-work__title {
    font-size: 28px;
  }

  .progress-work__subtitle {
    margin-bottom: 50px;

    font-size: 14px;
  }

  .progress-work__wrapper {
    width: 290px;
    margin: 0 auto;
  }

  .progress-work__list {
    grid-template-columns: 1fr;
    gap: 10px 0;
    grid-template-areas: ".";
    justify-items: center;
  }

  .progress-work__line {
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;

    width: 38px;
    height: 100%;
  }

  .progress-work__line-desktop {
    display: none;
  }

  .progress-work__line-mobile {
    display: block;
    width: 38px;
    height: 462px;
  }

  [data-aos="custom-work"] {
    overflow: hidden;
    clip-path: inset(0 0 100% 0);
    transition: 1.2s cubic-bezier(0.4, 0.08, 0, 1.03);
  }

  [data-aos="custom-work"].aos-animate {
    clip-path: inset(0 0 0 0);
  }
}

@media (max-width: 767.98px) {
  .progress-work__title {
    font-size: 26px;
  }

  .progress-work__subtitle {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 45px;
  }

  .progress-work {
    margin-bottom: 90px;
  }
}
</style>
