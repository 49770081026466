<template>
  <div id="popup-services" class="popup-services" style="display: none">
    <div class="popup-services__left">
      <img
        class="popup-services__img"
        :src="$mediaWp(content.services_list_id_img, 'services')"
        alt=""
        v-if="content.services_list_id_img"
      />
    </div>
    <div class="popup-services__right">
      <div
        class="popup-services__title"
        v-if="content.services_list_name"
        v-html="content.services_list_name"
      ></div>
      <div
        class="popup-services____desc"
        v-if="content.services_list_desc"
        v-html="content.services_list_desc"
      ></div>
      <div class="popup-services__price">
        <span
          class="popup-services__price-main"
          v-if="content.services_list_price"
          >{{ content.services_list_price }}</span
        >
        <span
          class="popup-services__price-old"
          v-if="content.services_list_price_old"
          >{{ content.services_list_price_old }}</span
        >
      </div>
      <form class="popup-services__form" action="#">
        <p
          :class="
            errors ? 'popup-services__field error' : 'popup-services__field'
          "
        >
          <Label parent-class="popup-services__label">{{
            errors ? "* Поле “Телефон” не указано" : "* Телефон"
          }}</Label>
          <span class="input_wrapper">
            <InputText
              parent-class="popup-services__input"
              name="popup-services-phone"
              v-input-mask
              v-model="dataForm.your_phone"
              type="tel"
              placeholder="+ 7 ("
            />
          </span>
        </p>
        <Button
          parent-class="popup-services__button"
          v-on:click.prevent="submitForm"
          v-if="content.services_list_popup.services_list_popup_button"
          >{{ content.services_list_popup.services_list_popup_button }}</Button
        >
        <p class="popup-services_politics">{{ politics }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/element/InputTextApp.vue";
import Button from "@/components/element/ButtonApp.vue";
import Label from "@/components/element/LabelApp.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { validation } from "@/helpers/index.js";

export default {
  components: {
    Button,
    InputText,
    Label,
  },
  data() {
    return {
      content: this.$store.state.servicesPopup.data,
      politics:
        this.$store.state.data.contentResponse.contacts.contacts_politics,
      dataForm: {
        id: 6,
        your_phone: "",
        your_name_service:
          this.$store.state.servicesPopup.data.services_list_name,
      },
      errors: false,
    };
  },
  methods: {
    submitForm() {
      this.errors = false;

      if (validation(this.dataForm.your_phone.length, 18)) {
        if (this.content.services_list_popup.services_list_popup_button_metka) {
          this.$metrika.reachGoal(
            this.content.services_list_popup.services_list_popup_button_metka
          );
        }

        this.$store.dispatch("submitForm", this.dataForm);

        this.dataForm = {
          id: 6,
          your_phone: "",
          your_name_service:
            this.$store.state.servicesPopup.data.services_list_name,
        };

        setTimeout(() => {
          Fancybox.close();
        }, 300);
      } else {
        this.errors = true;
      }
    },
  },
  mounted() {
    Fancybox.show(
      [
        {
          src: "#popup-services",
          type: "inline",
        },
      ],
      {
        on: {
          destroy: () => {
            this.$store.commit("updateServicesPopup", {
              status: false,
              data: null,
            });
          },
        },
        dragToClose: false,
        autoFocus: false,
        template: {
          closeButton:
            '<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 0.700394 -0.713757 0.700394 20 -0.000244141)" fill="#929292"/><rect width="1" height="27" rx="0.5" transform="matrix(0.713757 -0.700394 0.713757 0.700394 1.00003 0.999756)" fill="#929292"/></svg>',
        },
      }
    );
  },
};
</script>

<style>
.popup-services {
  display: flex;
  flex-direction: row;
  width: 798px;
  padding: 0;
}

.popup-services__left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  flex-shrink: 0;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  overflow: hidden;
}

.popup-services__img {
  position: absolute;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

.popup-services__right {
  width: 50%;
  flex-shrink: 0;
  padding: 70px 36px;
}

.popup-services__title {
  margin-bottom: 25px;

  font-family: var(--title4-fonts-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--title4-fonts-size);
  line-height: var(--title4-line-height);
  color: var(--title4-color-white);
}

.popup-services____desc {
  max-height: 84px;
  margin-bottom: 25px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: 1.5;
  color: var(--subtitle3-color-white);
}

.popup-services__price {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.popup-services__price-main {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}

.popup-services__price-old {
  position: relative;

  margin-left: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #929292;
}

.popup-services__price-old:after {
  content: "";

  position: absolute;
  top: 9px;
  left: 0;

  width: 100%;
  height: 1px;

  background-color: #929292;
}

.popup-services__label {
  margin-bottom: 5px;
}

.popup-services__input {
  margin-bottom: 10px;
}

.popup-services__button {
  width: 100%;
  margin-bottom: 25px;
}

.popup-services_politics {
  font-family: var(--subtitle4-fonts-family);
  font-size: var(--subtitle4-fonts-size);
  color: var(--subtitle4-color-white);
}

#popup-services .carousel__button.is-close {
  top: 0px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 0;
  padding: 5px;
}

#popup-services .carousel__button svg {
  width: auto;
  height: auto;
  stroke: none;
  filter: none;
  stroke-width: 1px;
  stroke-linejoin: bevel;
  stroke-linecap: round;
}

#popup-services .carousel__button svg rect {
  will-change: transform;
}

@media screen and (max-width: 1599.98px) {
  .popup-services____desc {
    font-size: 12px;
  }
}

@media screen and (max-width: 1199.98px) {
  .popup-services {
    width: 698px;
  }
  .popup-services__right {
    padding: 50px 26px;
  }

  .popup-services__title {
    margin-bottom: 15px;
  }

  .popup-services____desc {
    margin-bottom: 15px;
  }

  .popup-services__price {
    margin-bottom: 15px;
  }

  .popup-services__price-main {
    font-size: 18px;
  }

  .popup-services__button {
    width: 100%;
    margin-bottom: 20px;
  }

  .popup-services__politics-checkbox {
    margin-right: 10px;
  }

  .popup-services_politics {
    font-size: 10px;
  }
}

@media screen and (max-width: 991.98px) {
  .popup-services {
    width: 620px;
  }

  .popup-services__title {
    margin-bottom: 15px;
  }

  .popup-services____desc {
    max-height: 65px;

    font-size: 12px;
  }

  .popup-services__price {
    margin-bottom: 15px;
  }

  .popup-services__price-main {
    font-size: 18px;
  }

  .popup-services__button {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .popup-services {
    width: 500px;
  }
}

@media screen and (max-width: 532.98px) {
  .popup-services {
    display: flex;
    flex-direction: column;
    width: 290px;
    height: auto;
    padding: 0;
  }

  .popup-services__left {
    width: 100%;
    height: 270px;
  }

  .popup-services__img {
    object-position: top center;
  }

  .popup-services__right {
    width: 100%;
    padding: 20px 20px 30px 20px;
  }

  .popup-services__title {
    margin-bottom: 8px;
  }

  .popup-services____desc {
    margin-bottom: 15px;
  }

  .popup-services__price {
    margin-bottom: 10px;
  }

  .popup-services__button {
    margin-bottom: 15px;
  }
}
</style>
